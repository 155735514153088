import styled from 'styled-components/macro'
import {
  spacing,
  display,
  sizing,
  positions,
  borders,
  palette,
  flexbox,
  typography,
} from '@material-ui/system'

const Box = styled.div`
${ props => props.zIndex ? 'z-index:' + props.zIndex : ''};
${ props => props.cursor==='pointer' ? 'cursor: url(\'/f.png\'), auto;' : ''};
${ props => props.overflow && `overflow: ${props.overflow}`}
${ props => props.roboto ? 'font-family: Roboto' : ''};
${ props => props.cinzel ? 'font-family: "Cinzel", serif' : ''};
${ props => props.whiteSpace ? 'white-space: ' + props.whiteSpace : ''};
${ props => props.lineHeight ? 'line-height: ' + props.lineHeight : ''};
${ props => props.textOverflow ? 'text-overflow: ' + props.textOverflow : ''};
${ props => props.pointerEvents ? 'pointer-events: ' + props.pointerEvents : ''};
${ props => props.textTransform ? 'text-transform: ' + props.textTransform : ''};

${ spacing};
${ display};
${ palette};
${ borders};
${ flexbox};
${ sizing};
${ positions};
${ typography};
`

export default Box
